import React from "react";

const config = {
  ROMS: {
    owlia: {
      name: "The Legends of Owlia",
      description: (
        <span>
          <a
            href="http://www.gradualgames.com/p/the-legends-of-owlia_1.html"
            target="_blank"
          >
            Owlia by Gradual Games
          </a>{" "}
          /{" "}
          <a href="http://www.infiniteneslives.com/owlia.php" target="_blank">
            Buy the cartridge
          </a>
        </span>
      ),
      url: "https://cdn.jsdelivr.net/gh/bfirsh/jsnes-roms@master/owlia.nes"
    },
    mario: {
      name: "Mario",
      description: <span>Super Mario NES rom</span>,
      url: "https://s3.amazonaws.com/nes.antogin.dev/games/Super_Mario_Bros.nes"
    }
  },
  GOOGLE_ANALYTICS_CODE: process.env.REACT_APP_GOOGLE_ANALYTICS_CODE,
  SENTRY_URI: process.env.REACT_APP_SENTRY_URI
};

export default config;
